<script setup lang="ts">
import { fromNow } from 'assets/js/filters/dayjs';
import type { Project } from '~/stores/Project.types';

const props = defineProps<{ data: Project }>();
</script>

<template>
  <nuxt-link
    :to="`/projects/${data.slug || data.id}`"
    class="project-card"
  >
    <div
      v-if="props.data.payed_status"
      class="project-card__premium"
    >
      <h5>{{ props.data.title }}</h5>
      <div
        class="premium-tag"
        title="Этот проект оплачен"
      >
        PRO
      </div>
    </div>
    <h5 v-else>
      {{ props.data.title }}
    </h5>
    <p>
      {{ props.data.description }}
    </p>
    <div class="project-card__group">
      <div class="project-card__pin">
        {{ props.data.type }}
      </div>
      <div class="project-card__time">
        {{ fromNow(props.data.created_at).replace(/ /g, '&nbsp;') }}
      </div>
    </div>
  </nuxt-link>
</template>

<style scoped lang="scss">
.project-card {
  position: relative;
  display: grid;
  align-items: flex-start;
  border-radius: 10px;
  background: #fff;
  padding: 14px 16px;
  box-shadow: 0px 0px 3px 0px rgba(78, 0, 177, 0.15);
  height: 100%;
  @include media-breakpoint-down(sm) {
    padding: 14px 12px;
    margin-left: -10px;
    margin-right: -10px;
  }

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(79, 70, 229, 0.3);

    .project-card__premium .premium-tag {
      background-size: 100% 100%;
    }
  }

  .project-card__premium {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;
    //@include media-breakpoint-down(xl) {
    //    top: -8px;
    //    right: -8px;
    //}
    //@include media-breakpoint-down(sm) {
    //    top: -18px;
    //    right: -8px;
    //}

    .premium-tag {
      border-radius: 6px;
      background: $gradient-light;
      background-size: 200% 100%;
      font-size: 12px;
      padding: 3px 5px;
      color: white;
      transition: background-size 0.3s;
    }
  }

  h5 {
    color: $primary;
    margin-bottom: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 500;
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 12px;
    color: black;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
      line-height: 150%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    align-self: flex-end;
  }

  &__pin {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px;
    //border-radius: 9px;
    color: $gray;
    font-size: 12px;
    //padding: 3px 9px;
    //border: 1px solid $primary;
    line-height: 120%;
    //background: linear-gradient(248deg, rgba(140, 70, 229, 0.70) 7.4%, rgba(79, 70, 229, 0.70) 64.73%);

    @include media-breakpoint-down(md) {
      height: 100%;
    }
    @include media-breakpoint-down(sm) {
      max-width: 160px;
      line-height: 120%;
      font-size: 11px;
    }
  }

  &__time {
    color: $gray;
    line-height: 150%;
    font-size: 12px;
    font-weight: 400;
    justify-self: flex-end;
    align-self: center;
    text-align: right;
    @include media-breakpoint-down(md) {
      line-height: 120%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 11px;
    }
  }
}
</style>
